import axios from "../utils/axios";

const getApiSecret = async ({ user_id }) => {
  try {
    console.log({
      user_id,
    });
    let response = await axios({
      method: "GET",
      url: `/api/secrets`,
      params: {
        user_id,
      },
    });
    console.log("ApiSecretService :: getApiSecret :: ", response);
    return Promise.resolve({
      status: true,
      token: response.data.data,
      /* token: response.data.token,
        user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const postApiSecret = async ({ user_id }) => {
  try {
    console.log({
      user_id,
    });
    let response = await axios({
      method: "POST",
      url: `/api/secrets`,
      data: {
        user_id,
      },
    });
    console.log("ApiSecretService :: postApiSecret :: ", response);
    return Promise.resolve({
      status: true,
      token: response.data.data,
      /* token: response.data.token,
          user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

export { getApiSecret, postApiSecret };
