import axios from "../utils/axios";

const getTenants = async (user_id) => {
  try {
    let response = await axios({
      method: "GET",
      url: `api/tenants/getTenants`,
      params: {
        user_id,
      },
    });
    console.log("TenantService :: getTenants :: ", response);
    return Promise.resolve({
      status: true,
      tenants: response.data.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.resolve({
      status: false,
      tenants: null,
    });
  }
};

const addTenant = async (user_id, formValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/tenants/addTenant`,
      data: {
        user_id,
        formValues,
      },
    });
    console.log("TenantService :: addTenant :: ", response);
    return Promise.resolve({
      status: true,
      tenant: response.data.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.resolve({
      status: false,
      tenant: null,
    });
  }
};

const updateTenant = async (user_id, tenant_id, receivedValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/tenants/updateTenant`,
      data: {
        user_id,
        tenant_id,
        receivedValues,
      },
    });
    console.log("TenantService :: updateTenant :: ", response);
    return Promise.resolve({
      status: true,
      tenant: response.data.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.resolve({
      status: false,
      tenant: null,
    });
  }
};

export { getTenants, addTenant, updateTenant };
