import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { createContext, useEffect, useState } from "react";

export const AddToHandbookContext = createContext();

const AddToHandbookContextProvider = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(-1);
  let timeOut;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const _files = [
    { id: 1, name: "Hedge Fund Bucket", accountsCount: 23, checked: true },
    {
      id: 2,
      name: "Asset Management Customers",
      accountsCount: 9,
      checked: false,
    },
    { id: 3, name: "Automobile Techs", accountsCount: 17, checked: false },
    { id: 4, name: "Green Infra Companies", accountsCount: 41, checked: false },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <AddToHandbookContext.Provider
        value={{
          openDialog,
          setOpenDialog,
          handleCloseDialog,
          handleOpenDialog,
          _files,
          selectedAccount,
          setSelectedAccount,
        }}
      >
        {children}
      </AddToHandbookContext.Provider>
    </>
  );
};

export default AddToHandbookContextProvider;
