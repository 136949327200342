import { createContext, useState } from "react";

export const AttributeOptionsOrderContext = createContext();

const AttributeOptionsOrderContextProvider = ({ children }) => {
  const [contextOrder, setContextOrder] = useState("descending");
  const [contextOrderSeries, setContextOrderSeries] = useState([]);

  return (
    <>
      <AttributeOptionsOrderContext.Provider
        value={{
          contextOrder,
          setContextOrder,
          contextOrderSeries,
          setContextOrderSeries,
        }}
      >
        {children}
      </AttributeOptionsOrderContext.Provider>
    </>
  );
};

export default AttributeOptionsOrderContextProvider;
