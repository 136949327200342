import axios from "../utils/axios";

const getUsers = async ({ user_id, tenant_id }) => {
  try {
    console.log({
      user_id,
      tenant_id,
    });
    let response = await axios({
      method: "GET",
      url: `/api/users/getUsers`,
      params: {
        user_id,
        tenant_id,
      },
    });
    console.log("UserService :: getUsers :: ", response);
    return Promise.resolve({
      status: true,
      users: response.data.data,
      /* token: response.data.token,
        user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      users: null,
    });
  }
};

const addUser = async (user_id, tenant_id, formValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/users/addUser`,
      data: {
        user_id,
        tenant_id,
        formValues,
      },
    });
    console.log("UserService :: addUser :: ", response);
    return Promise.resolve({
      status: true,
      user: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      users: null,
    });
  }
};

const updateUser = async (user_id, admn_sr_id, receivedValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/users/updateUser`,
      data: {
        user_id,
        admn_sr_id,
        receivedValues,
      },
    });
    console.log("UserService :: updateUser :: ", response);
    return Promise.resolve({
      status: true,
      user: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      users: null,
    });
  }
};

const getUserDetails = async (user_id, admn_sr_id) => {
  try {
    let response = await axios({
      method: "GET",
      url: `api/users/getUser`,
      data: {
        user_id,
        admn_sr_id,
      },
    });
    console.log("UserService :: getUserDetails :: ", response);
    return Promise.resolve({
      status: true,
      user: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      user: null,
    });
  }
};

export { getUsers, addUser, updateUser, getUserDetails };
