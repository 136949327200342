import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));

export const PageWelcome = Loadable(lazy(() => import("../pages/PageWelcome")));

export const PageSettings = Loadable(
  lazy(() => import("../pages/PageSettings"))
);

export const PageHandbook = Loadable(
  lazy(() => import("../pages/PageHandbook"))
);

export const PageHandbookView = Loadable(
  lazy(() => import("../pages/PageHandbookView"))
);

export const PageResetPassword = Loadable(
  lazy(() => import("../pages/PageResetPassword"))
);

export const PageNewPassword = Loadable(
  lazy(() => import("../pages/PageNewPassword"))
);

export const PageReports = Loadable(lazy(() => import("../pages/PageReports")));

export const PageReportConfigure = Loadable(
  lazy(() => import("../pages/PageReportConfigure"))
);

export const PageStories = Loadable(lazy(() => import("../pages/PageStories")));
export const PageSingleStoryView = Loadable(
  lazy(() => import("../pages/PageSingleStoryView"))
);
export const PageStoryCreate = Loadable(
  lazy(() => import("../pages/PageStoryCreate"))
);
export const PageStoryEdit = Loadable(
  lazy(() => import("../pages/PageStoryEdit"))
);
export const PageReference = Loadable(
  lazy(() => import("../pages/PageReference"))
);

export const PageAttributeOptions = Loadable(
  lazy(() => import("../pages/PageAttributeOptions"))
);

export const PageAttributeOptionCreate = Loadable(
  lazy(() => import("../pages/PageAttributeOptionCreate"))
);

export const PageAttributeOptionEdit = Loadable(
  lazy(() => import("../pages/PageAttributeOptionEdit"))
);

export const PageAttributes = Loadable(
  lazy(() => import("../pages/PageAttributes"))
);

export const PageAttributeCreate = Loadable(
  lazy(() => import("../pages/PageAttributeCreate"))
);

export const PageAttributeEdit = Loadable(
  lazy(() => import("../pages/PageAttributeEdit"))
);

export const PageUsers = Loadable(lazy(() => import("../pages/PageUsers")));

// export const PageUserCreate = Loadable(
//   lazy(() => import("../pages/PageUserCreate"))
// );

// export const PageUserEdit = Loadable(
//   lazy(() => import("../pages/PageUserEdit"))
// );

export const PageUserCreate = Loadable(
  lazy(() => import("../pages/PageUserDetails"))
);

export const PageUserEdit = Loadable(
  lazy(() => import("../pages/PageUserDetails"))
);

export const PageTenants = Loadable(lazy(() => import("../pages/PageTenants")));

export const PageTenantCreate = Loadable(
  lazy(() => import("../pages/PageTenantCreate"))
);

export const PageTenantEdit = Loadable(
  lazy(() => import("../pages/PageTenantEdit"))
);

export const PageAccounts = Loadable(
  lazy(() => import("../pages/PageAccounts"))
);

export const PageAccountCreate = Loadable(
  lazy(() => import("../pages/PageAccountDetails"))
);

export const PageAccountEdit = Loadable(
  lazy(() => import("../pages/PageAccountDetails"))
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
