import axios from "../utils/axios";

const getReports = async ({ user_id }) => {
  try {
    // console.log({
    //   user_id,
    // });
    let response = await axios({
      method: "GET",
      url: `/api/reports`,
      params: {
        user_id,
      },
    });
    console.log("ReportService :: getReports :: ", response);
    return Promise.resolve({
      status: true,
      reports: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      reports: null,
    });
  }
};

export { getReports };
