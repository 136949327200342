import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession } from "./utils";
import { AuthService } from "../services";
import { localStorageKeys } from "src/Constatnts";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";

import { useSnackbar } from "../components/snackbar";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem(localStorageKeys.token)
        : "";

      const userId = storageAvailable
        ? localStorage.getItem(localStorageKeys.userId)
        : "";

      if (accessToken && isValidToken(accessToken) && userId) {
        setSession(accessToken);

        const response = await AuthService.getLoggedInUser(userId);
        if (!response.status) throw new Error(response.message);

        const { user } = response;

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      //console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  window.onmessage = ({ data }) => {
    const { type, code, isLoggedout, message } = data;
    const codes = ["403A", "403B"];
    if (type === "Auth" && codes.includes(code) && isLoggedout) {
      if (code === "403B") enqueueSnackbar(message, { variant: "error" });
      logout();
    }
  };

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await AuthService.login({ email, password });
    console.log("LOGIN :: ", response);
    const { token: accessToken, user, status, message } = response;

    if (!status) throw new Error(message);

    setSession(accessToken);
    localStorage.setItem(localStorageKeys.userId, user.id);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem(localStorageKeys.token, accessToken);
    localStorage.setItem(localStorageKeys.userId, user.id);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
