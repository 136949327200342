// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";
import { EntityTypes, userRoles } from "src/Constatnts";
import BookIcon from "@mui/icons-material/Book";
import AssessmentIcon from "@mui/icons-material/Assessment";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  account: icon("material-symbols:settings-account-box-outline"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "General",
    roles: [userRoles.salesRepresentative, userRoles.admin],
    items: [
      {
        title: "Stories",
        path: PATH_DASHBOARD.stories.root,
        icon: ICONS.dashboard,
        //roles: [userRoles.salesRepresentative, userRoles.admin],
      },
      {
        title: "Find a reference",
        path: PATH_DASHBOARD.reference,
        icon: ICONS.analytics,
        //roles: [userRoles.salesRepresentative, userRoles.admin],
      },
      {
        title: "Reports",
        path: PATH_DASHBOARD.reports.root,
        icon: <AssessmentIcon />,
        // roles: [userRoles.salesRepresentative, userRoles.admin],
      },
      {
        title: "Reference Book",
        path: PATH_DASHBOARD.handbook.root,
        icon: <BookIcon />,
        // roles: [userRoles.salesRepresentative, userRoles.admin],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Admin",
    roles: [userRoles.admin],
    items: [
      {
        title: "users",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        /* children: [], */
        // roles: [userRoles.admin],
      },
      {
        title: "accounts",
        path: PATH_DASHBOARD.account.root,
        icon: ICONS.user,
        /* children: [], */
        // roles: [userRoles.admin],
      },
      {
        title: "account Attributes",
        path: PATH_DASHBOARD.attributes.root(EntityTypes.accounts),
        icon: ICONS.user,
        /* children: [], */
        // roles: [userRoles.admin],
      },
      {
        title: "Story Attributes",
        path: PATH_DASHBOARD.attributes.root(EntityTypes.stories),
        icon: ICONS.user,
        /* children: [], */
        // roles: [userRoles.admin],
      },
    ],
  },
  {
    subheader: "Super Admin",
    roles: [userRoles.superAdmin],
    items: [
      {
        title: "tenants",
        path: PATH_DASHBOARD.tenant.root,
        icon: ICONS.user,
        /* children: [], */
        // roles: [userRoles.superAdmin],
      },
    ],
  },
];

export default navConfig;
