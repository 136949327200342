// import PropTypes from 'prop-types';
// @mui
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Divider,
  TableRow,
  Typography,
  Button,
  TextField,
} from "@mui/material";
// utils
// import { fDate } from '../../../../utils/formatTime';
// import { fCurrency } from '../../../../utils/formatNumber';

// components
import { useNavigate } from "react-router";
import Iconify from "../../components/iconify";
import { useAuthContext } from "src/auth/useAuthContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import { addTenant, updateTenant } from "src/services/tenant.service";

// import InvoiceToolbar from './InvoiceToolbar';
//
// import InvoiceToolbar from '';

// ----------------------------------------------------------------------

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  "& td": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(3),
}));

export default function TenantDetails({
  formValues,
  setFormValues,
  isEditPage,
  initialTenantState,
  id,
  is_active,
  details,
}) {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const roles = [
    { name: "Admin", id: 2 },
    { name: "Sales Representative", id: 3 },
  ];

  const handelUpdateTenant = async (e, user_id) => {
    e.preventDefault();
    try {
      const response = await updateTenant(user_id, formValues.id, formValues);
      if (response.status) {
        navigate(PATH_DASHBOARD.tenant.root);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelCreateTenant = async (e, user_id) => {
    e.preventDefault();

    try {
      const response = await addTenant(user_id, formValues);
      if (response.status) {
        navigate(PATH_DASHBOARD.tenant.root);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card sx={{ pt: 2, px: 5 }} elevation={3} variant="outlined">
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <Typography variant="h4">Add a new tenant</Typography>
          </Grid>

          {!isEditPage ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // sx={{
                    //   width: "536px",
                    //   marginBottom: "10px",
                    // }}
                    fullWidth
                    label="Tenant Name"
                    value={formValues.tenant_name}
                    name="tenant_name"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // sx={{
                    //   width: "260px",
                    //   marginRight: "16px",
                    //   marginBottom: "10px",
                    // }}
                    fullWidth
                    type="email"
                    label="Email"
                    value={formValues.contact_email}
                    name="contact_email"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // sx={{
                    //   width: "260px",
                    //   marginBottom: "10px",
                    // }}
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Phone Number"
                    value={formValues.contact_phone}
                    name="contact_phone"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  User Info
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // sx={{
                    //   width: "260px",
                    //   marginRight: "16px",
                    //   marginBottom: "10px",
                    // }}
                    fullWidth
                    type="text"
                    label="First Name"
                    value={formValues.first_name}
                    name="first_name"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // sx={{
                    //   width: "260px",
                    //   marginRight: "16px",
                    //   marginBottom: "10px",
                    // }}
                    fullWidth
                    type="text"
                    label="Last Name"
                    value={formValues.last_name}
                    name="last_name"
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Tenant Name"
                    value={formValues.tenant_name}
                    name="tenant_name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Phone Number"
                    value={formValues.contact_phone}
                    name="contact_phone"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider sx={{ mt: 3, mb: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} />
              <Grid item xs={3}>
                {isEditPage ? null : (
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setFormValues(initialTenantState);
                    }}
                  >
                    RESET
                  </Button>
                )}
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={(e) => {
                    isEditPage
                      ? handelUpdateTenant(e, user.id)
                      : handelCreateTenant(e, user.id);
                  }}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <br />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
