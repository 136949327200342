export const EntityTypes = {
  accounts: "ACCOUNTS",
  stories: "STORIES",
};

export const StoryEntityFieldTypes = {
  singleSelectDropdown: "SINGLE_SELECT_DROPDOWN",
  multiSelectDropdown: "MULTI_SELECT_DROPDOWN",
  singleLineTextfield: "SINGLE_LINE_TEXTFIELD",
  multiLineTextfield: "MULTI_LINE_TEXTFIELD",
  textfield: "TEXTFIELD",
};

export const StoryEntityFieldTypeArray = [
  "SINGLE_SELECT_DROPDOWN",
  "MULTI_SELECT_DROPDOWN",
  "TEXTFIELD",
  /* "SINGLE_LINE_TEXTFIELD",
  "MULTI_LINE_TEXTFIELD", */
];

export const AccountEntityFieldTypeArray = [
  "SINGLE_SELECT_DROPDOWN",
  "MULTI_SELECT_DROPDOWN",
];

export const AccountEntityFieldTypes = {
  singleSelectDropdown: "SINGLE_SELECT_DROPDOWN",
  multiSelectDropdown: "MULTI_SELECT_DROPDOWN",
};

export const localStorageKeys = { token: "_TOKEN_", userId: "_USER_ID_" };

export const noOfElementsPerRowInSearchReference = 4;

export const userRoles = {
  superAdmin: 1,
  admin: 2,
  salesRepresentative: 3,
};
