import { Navigate, useRoutes, Outlet } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  LoginPage,
  PageStories,
  PageReference,
  PageReports,
  PageSingleStoryView,
  PageStoryCreate,
  PageUsers,
  PageAccounts,
  PageStoryEdit,
  PageAttributes,
  PageUserCreate,
  PageUserEdit,
  PageAccountCreate,
  PageAccountEdit,
  PageAttributeCreate,
  PageAttributeEdit,
  PageAttributeOptions,
  PageAttributeOptionCreate,
  PageAttributeOptionEdit,
  PageTenants,
  PageTenantEdit,
  PageWelcome,
  PageSettings,
  PageResetPassword,
  PageNewPassword,
  PageReportConfigure,
  PageHandbook,
  PageHandbookView,
} from "./elements";
import RoleBasedGuard from "src/auth/RoleBasedGuard";
import { userRoles } from "src/Constatnts";
import PageTenantCreate from "src/pages/PageTenantCreate";

// import {AccountStoryView} from "../sections/@dashboard/stories/AccountStoryView"
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <PageWelcome />, index: true },
        {
          path: "stories",
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[userRoles.salesRepresentative, userRoles.admin]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageStories />,
              index: true,
            },
            { path: "viewstory", element: <PageSingleStoryView /> },
            { path: ":id", element: <PageSingleStoryView /> },
            { path: ":id/edit", element: <PageStoryEdit /> },
            { path: "new", element: <PageStoryCreate /> },
          ],
        },
        {
          path: "reference",
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[userRoles.salesRepresentative, userRoles.admin]}
            >
              <PageReference />
            </RoleBasedGuard>
          ),
        },

        // {
        //   path: "reports",
        //   element: (
        //     <RoleBasedGuard
        //       hasContent={true}
        //       roles={[userRoles.salesRepresentative, userRoles.admin]}
        //     >
        //       <Outlet />
        //     </RoleBasedGuard>
        //   ),
        //   children: [
        //     {
        //       element: <PageReports />,
        //       index: true,
        //     },
        //     { path: "configure", element: <PageReportConfigure /> },
        //   ],
        // },
        /* { path: "welcome", element: <PageWelcome /> }, */
        { path: "settings", element: <PageSettings /> },
        {
          path: "users",
          element: (
            <RoleBasedGuard hasContent={true} roles={[userRoles.admin]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageUsers />,
              index: true,
            },
            { path: "new", element: <PageUserCreate /> },
            { path: ":id/edit", element: <PageUserEdit /> },
          ],
        },
        {
          path: "accounts",
          element: (
            <RoleBasedGuard hasContent={true} roles={[userRoles.admin]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageAccounts />,
              index: true,
            },
            { path: "new", element: <PageAccountCreate /> },
            { path: ":id/edit", element: <PageAccountEdit /> },
          ],
        },
        {
          path: "attributes/:type",
          element: (
            <RoleBasedGuard hasContent={true} roles={[userRoles.admin]}>
              <Outlet />
            </RoleBasedGuard>
          ),

          children: [
            {
              element: <PageAttributes />,
              index: true,
            },
            { path: "new", element: <PageAttributeCreate /> },
            { path: "edit", element: <PageAttributeEdit /> },
            {
              path: "options",
              children: [
                {
                  element: <PageAttributeOptions />,
                  index: true,
                },
                { path: "new", element: <PageAttributeOptionCreate /> },
                { path: "edit", element: <PageAttributeOptionEdit /> },
              ],
            },
          ],
        },
        {
          path: "tenants",
          element: (
            <RoleBasedGuard hasContent={true} roles={[userRoles.superAdmin]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageTenants />,
              index: true,
            },
            { path: "new", element: <PageTenantCreate /> },
            { path: ":id/edit", element: <PageTenantEdit /> },
          ],
        },
        // { path: "reports", element: <PageReports /> },
        {
          path: "reports",
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[userRoles.salesRepresentative, userRoles.admin]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageReports />,
              index: true,
            },
            { path: "configure", element: <PageReportConfigure /> },
          ],
        },
        {
          path: "handbook",
          element: (
            <RoleBasedGuard
              hasContent={true}
              roles={[userRoles.salesRepresentative, userRoles.admin]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              element: <PageHandbook />,
              index: true,
            },
            { path: "view", element: <PageHandbookView /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "reset-password", element: <PageResetPassword /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "new-password", element: <PageNewPassword /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
