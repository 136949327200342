import axios from "../utils/axios";

const getHandbook = async (user_id, contents) => {
  try {
    console.log({
      user_id,
      contents,
    });
    let response = await axios({
      method: "GET",
      url: `/api/handbook`,
      params: {
        user_id,
        contents,
      },
    });
    console.log("HandbookService :: getHandbook :: ", response);
    return Promise.resolve({
      status: true,
      buckets: response.data.data,
      /* token: response.data.token,
          user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      buckets: null,
    });
  }
};

const addBucket = async (user_id, bucketName) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/handbook/buckets`,
      data: {
        user_id,
        bucketName,
      },
    });
    console.log("HandbookService :: addBucket :: ", response);
    return Promise.resolve({
      status: true,
      bucket: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      bucket: null,
    });
  }
};

const addAccountToBucket = async (user_id, accountId, bucketIdsList) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/handbook/buckets/content`,
      data: {
        user_id,
        accountId,
        bucketIdsList,
      },
    });
    console.log("HandbookService :: addAccountToBucket :: ", response);
    return Promise.resolve({
      status: true,
      bucket: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      bucket: null,
    });
  }
};

const deleteBucket = async (user_id, bucketId) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/handbook/buckets`,
      data: {
        user_id,
        bucketId,
      },
    });
    console.log("HandbookService :: deleteBucket :: ", response);
    return Promise.resolve({
      status: true,
      bucket: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      bucket: null,
    });
  }
};

const removeAccountFromBucket = async (user_id, bucketId, accountId) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/handbook/buckets/content`,
      data: {
        user_id,
        bucketId,
        accountId,
      },
    });
    console.log("HandbookService :: removeAccountFromBucket :: ", response);
    return Promise.resolve({
      status: true,
      bucket: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      bucket: null,
    });
  }
};

export {
  getHandbook,
  addBucket,
  addAccountToBucket,
  deleteBucket,
  removeAccountFromBucket,
};
