import fileDownload from "js-file-download";
import axios from "../utils/axios";

const getAllAccounts = async ({ user_id, tenant_id }) => {
  try {
    console.log({
      user_id,
      tenant_id,
    });
    let response = await axios({
      method: "GET",
      url: `/api/accounts/getAllAccounts`,
      params: {
        user_id,
        tenant_id,
      },
    });
    console.log("AccountService :: getAllAccounts :: ", response);
    return Promise.resolve({
      status: true,
      accounts: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const getSimilarAccounts = async ({ user_id, tenant_id, account_id }) => {
  try {
    console.log({
      user_id,
      tenant_id,
      account_id,
    });
    let response = await axios({
      method: "GET",
      url: `api/stories/getSimilarAccounts`,
      params: {
        user_id,
        tenant_id,
        account_id,
      },
    });
    console.log("AccountService :: getSimilarAccounts :: ", response);
    return Promise.resolve({
      status: true,
      accounts: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const getAllActiveAccounts = async ({ user_id, tenant_id }) => {
  try {
    console.log({
      user_id,
      tenant_id,
    });
    let response = await axios({
      method: "GET",
      url: `api/accounts/getAllActiveAccounts`,
      params: {
        user_id,
        tenant_id,
      },
    });
    console.log("AccountService :: getSimilarAccounts :: ", response);
    return Promise.resolve({
      status: true,
      accounts: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const getAccountAttributes = async ({
  user_id,
  tenant_id,
  isAttribute,
  onlyEnabled,
  entity_type,
  is_searchable,
}) => {
  try {
    console.log({
      user_id,
      tenant_id,
      isAttribute,
      onlyEnabled,
      entity_type,
      is_searchable,
    });
    let response = await axios({
      method: "GET",
      url: `api/entity/all`,
      params: {
        user_id,
        tenant_id,
        isAttribute,
        onlyEnabled,
        entity_type,
        is_searchable,
      },
    });
    console.log("AccountService :: getSimilarAccounts :: ", response);
    return Promise.resolve({
      status: true,
      filters: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      filters: null,
    });
  }
};

const getStoryAttributes = async ({
  user_id,
  tenant_id,
  isAttribute,
  onlyEnabled,
  entity_type,
  is_searchable,
}) => {
  try {
    console.log({
      user_id,
      tenant_id,
      isAttribute,
      onlyEnabled,
      entity_type,
      is_searchable,
    });
    let response = await axios({
      method: "GET",
      url: `api/entity/all`,
      params: {
        user_id,
        tenant_id,
        isAttribute,
        onlyEnabled,
        entity_type,
        is_searchable,
      },
    });
    console.log("AccountService :: getSimilarAccounts :: ", response);
    return Promise.resolve({
      status: true,
      filters: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      filters: null,
    });
  }
};

const getStoryHistories = async ({ user_id, story_id }) => {
  try {
    console.log({
      user_id,
      story_id,
    });
    let response = await axios({
      method: "GET",
      url: `api/stories/histories`,
      params: {
        user_id,
        story_id,
      },
    });
    console.log("AccountService :: getHistories :: ", response);
    return Promise.resolve({
      status: true,
      histories: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      histories: null,
    });
  }
};

const updateStory = async (user_id, story_id, account_id, receivedValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/stories/updateStory`,
      data: {
        user_id,
        story_id,
        account_id,
        receivedValues,
      },
      // headers: { Authorization: `Bearer ${token}` },
    });
    console.log("AccountService :: updateStory :: ", response);
    return Promise.resolve({
      status: true,
      data: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const updateStoryName = async (story_id, user_id, account_id, story_name) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/stories/updateStoryname`,
      data: {
        story_id,
        user_id,
        account_id,
        story_name,
      },
      // headers: { Authorization: `Bearer ${token}` },
    });
    console.log("AccountService :: updateStoryname :: ", response);
    return Promise.resolve({
      status: true,
      data: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const exportstory = async (user_id, storyData) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/stories/exportstory`,
      data: {
        user_id,
        storyData,
      },
      // headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    });
    fileDownload(response.data, response.headers["file-name"]);
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const getAllEntitiesWithAttributes = async ({
  user_id,
  tenant_id,
  isAttribute,
  onlyEnabled,
  entity_type,
  is_searchable,
}) => {
  try {
    console.log({
      user_id,
      tenant_id,
      isAttribute,
      onlyEnabled,
      entity_type,
      is_searchable,
    });
    let response = await axios({
      method: "GET",
      url: `api/entity/all`,
      params: {
        user_id,
        tenant_id,
        isAttribute,
        onlyEnabled,
        entity_type,
        is_searchable,
      },
    });
    console.log("AccountService :: getAllEntitiesWithAttributes :: ", response);
    return Promise.resolve({
      status: true,
      entities: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      entities: null,
    });
  }
};

const getStories = async (user_id, account_id, page) => {
  console.log({
    user_id,
    account_id,
    page,
  });
  try {
    let response = await axios({
      method: "GET",
      url: `api/stories/getStories`,
      params: {
        user_id,
        account_id,
        page,
      },
    });
    console.log("AccountService :: getStories :: ", response);
    return Promise.resolve({
      status: true,
      data: response.data.data,
      // filters: response.data.data
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

const getAccountAttributeList = async ({
  user_id,
  tenant_id,
  isAttribute,
  onlyEnabled,
  entity_type,
}) => {
  try {
    console.log({
      user_id,
      tenant_id,
      isAttribute,
      onlyEnabled,
      entity_type,
    });
    let response = await axios({
      method: "GET",
      url: `api/entity/all`,
      params: {
        user_id,
        tenant_id,
        isAttribute,
        onlyEnabled,
        entity_type,
      },
    });
    console.log("AccountService :: getAccountAttributeList :: ", response);
    return Promise.resolve({
      status: true,
      attributes: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributes: null,
    });
  }
};

const getStoryAttributeList = async ({
  user_id,
  tenant_id,
  isAttribute,
  onlyEnabled,
  entity_type,
}) => {
  try {
    console.log({
      user_id,
      tenant_id,
      isAttribute,
      onlyEnabled,
      entity_type,
    });
    let response = await axios({
      method: "GET",
      url: `api/entity/all`,
      params: {
        user_id,
        tenant_id,
        isAttribute,
        onlyEnabled,
        entity_type,
      },
    });
    console.log("AccountService :: getStoryAttributeList :: ", response);
    return Promise.resolve({
      status: true,
      attributes: response.data.data,
      /* token: response.data.token,
      user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributes: null,
    });
  }
};

const addAccount = async (user_id, tenant_id, formValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/accounts/addAccount`,
      data: {
        user_id,
        tenant_id,
        ...formValues,
      },
      // headers: { Authorization: `Bearer ${token}` },
    });
    console.log("UserService :: addAccount :: ", response);
    return Promise.resolve({
      status: true,
      account: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      account: null,
    });
  }
};

const updateAccount = async (
  user_id,
  tenant_id,
  account_id,
  receivedValues
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/accounts/updateAccount`,
      data: {
        user_id,
        tenant_id,
        account_id,
        ...receivedValues,
      },
      // headers: { Authorization: `Bearer ${token}` },
    });
    return Promise.resolve({
      status: true,
      account: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      account: null,
    });
  }
};

const updateAccountStatus = async (
  user_id,
  tenant_id,
  account_id,
  is_active
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/accounts/updateAccountStatus`,
      data: {
        user_id,
        tenant_id,
        account_id,
        is_active,
      },
    });
    return Promise.resolve({
      status: true,
      account: response.data.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.resolve({
      status: false,
      account: null,
    });
  }
};

const addEntity = async (data) => {
  console.log({ data });
  try {
    let response = await axios({
      method: "POST",
      url: `api/entity/add`,
      data: {
        ...data,
      },
    });
    console.log("AccountService :: addEntity :: ", response);
    return Promise.resolve({
      status: true,
      attribute: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attribute: null,
    });
  }
};

const updateEntity = async ({
  user_id,
  id,
  entity_name,
  is_required,
  is_searchable,
  field_type,
}) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/entity/update`,
      data: {
        user_id,
        entity_id: id,
        receivedValues: {
          user_id,
          entity_name,
          is_required,
          is_searchable,
          field_type,
        },
      },
    });
    console.log("AccountService :: updateEntity :: ", response);
    return Promise.resolve({
      status: true,
      attribute: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attribute: null,
    });
  }
};

const getAllAttributesByEntityId = async (user_id, tenant_id, entity_id) => {
  try {
    let response = await axios({
      method: "GET",
      url: `api/attributes/all`,
      params: {
        user_id,
        tenant_id,
        entity_id,
      },
    });
    console.log("AccountService :: getAllAttributesByEntityId :: ", response);
    return Promise.resolve({
      status: true,
      attributeOptions: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributeOptions: null,
    });
  }
};

const addAttribute = async (user_id, tenant_id, entity_id, attribute_name) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/attributes/add`,
      data: {
        user_id,
        tenant_id,
        entity_id,
        attribute_name,
      },
    });
    console.log("AccountService :: addAttribute :: ", response);
    return Promise.resolve({
      status: true,
      attributeOption: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributeOption: null,
    });
  }
};

const updateAttribute = async (user_id, attribute_id, attributeName) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/attributes/update`,
      data: {
        user_id,
        attribute_id,
        receivedValues: {
          user_id,
          attribute_name: attributeName,
        },
      },
    });
    console.log("AccountService :: updateAttribute :: ", response);
    return Promise.resolve({
      status: true,
      attributeOption: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributeOption: null,
    });
  }
};

const toggleEntityStatus = async (user_id, entity_id, entityStatus) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/entity/update`,
      data: {
        user_id,
        entity_id,
        receivedValues: {
          user_id,
          is_enabled: !entityStatus,
        },
      },
    });
    return Promise.resolve({
      status: true,
      attribute: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attribute: null,
    });
  }
};

const toggleAttributeStatus = async (
  user_id,
  attribute_id,
  attributeStatus
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/attributes/update`,
      data: {
        user_id,
        attribute_id,
        receivedValues: {
          user_id,
          is_enabled: !attributeStatus,
        },
      },
    });
    return Promise.resolve({
      status: true,
      attributeOption: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      attributeOption: null,
    });
  }
};

const searchStories = async (user_id, tenant_id, searchFields) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/stories/getCustomStories`,
      data: {
        user_id,
        tenant_id,
        searchFields,
      },
    });
    console.log("AccountService :: searchStories :: ", response);
    return Promise.resolve({
      status: true,
      searchedData: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      searchedData: [],
    });
  }
};

const deleteAccount = async (user_id, tenant_id, account_id) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/accounts/remove`,
      params: {
        user_id,
        tenant_id,
        account_id,
      },
    });
    console.log("AccountService :: deleteAccount :: ", response);
    return Promise.resolve({
      status: true,
      isSuccess: response.data.isSuccess,
      account: response.data.data,
      message: response.data.message,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      isSuccess: false,
      account: [],
      message: error.data.message,
    });
  }
};

const deleteAccountPermanently = async (user_id, tenant_id, account_id) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/accounts/remove/f`,
      params: {
        user_id,
        tenant_id,
        account_id,
      },
    });
    console.log("AccountService :: deleteAccountPermanently :: ", response);
    return Promise.resolve({
      status: true,
      isSuccess: response.data.isSuccess,
      account: response.data.data,
      message: response.data.message,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      isSuccess: false,
      account: [],
      message: error.data.message,
    });
  }
};

const deleteEntity = async (user_id, tenent_id, entity_id) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/entity/remove`,
      params: {
        user_id,
        tenent_id,
        entity_id,
      },
    });
    console.log("AccountService :: deleteEntity :: ", response);
    return Promise.resolve({
      status: true,
      isSuccess: response.data.isSuccess,
      entity: response.data.data,
      message: response.data.message,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      isSuccess: false,
      entity: [],
      message: error.data.message,
    });
  }
};

const deleteEntityPermanently = async (
  user_id,
  tenent_id,
  entity_id,
  entityType
) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/entity/remove/f`,
      params: {
        user_id,
        tenent_id,
        entity_id,
        entityType,
      },
    });
    console.log("AccountService :: deleteEntity :: ", response);
    return Promise.resolve({
      status: true,
      isSuccess: response.data.isSuccess,
      entity: response.data.data,
      message: response.data.message,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      isSuccess: false,
      entity: [],
      message: error.data.message,
    });
  }
};

const deleteAttribute = async (user_id, tenent_id, attribute_id) => {
  try {
    let response = await axios({
      method: "DELETE",
      url: `api/attributes/remove`,
      params: {
        user_id,
        tenent_id,
        attribute_id,
      },
    });
    return Promise.resolve({
      status: true,
      isSuccess: response.data.isSuccess,
      attributeOption: response.data.data,
      message: response.data.message,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      isSuccess: false,
      attributeOption: [],
      message: error.data.message,
    });
  }
};

export {
  getAllAccounts,
  getSimilarAccounts,
  getAllActiveAccounts,
  getAccountAttributes,
  getAllEntitiesWithAttributes,
  getStoryAttributes,
  getStories,
  updateStory,
  updateStoryName,
  getStoryHistories,
  exportstory,
  getAccountAttributeList,
  getStoryAttributeList,
  addAccount,
  updateAccount,
  updateAccountStatus,
  addEntity,
  updateEntity,
  getAllAttributesByEntityId,
  addAttribute,
  updateAttribute,
  toggleEntityStatus,
  toggleAttributeStatus,
  searchStories,
  deleteAccount,
  deleteAccountPermanently,
  deleteEntity,
  deleteEntityPermanently,
  deleteAttribute,
};
