import axios from "axios";
// config
import { HOST_API_KEY } from "../config-global";
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Axios Interceptor :: ", error.response.status);

    if (error.response.status === 403) {
      window.postMessage({
        type: "Auth",
        isLoggedout: true,
        code: error.response.data.code,
        message: error.response.data.data,
      });
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
