import axios from "../utils/axios";

const login = async (authDto) => {
  try {
    let response = await axios({
      method: "POST",
      url: `/api/auth/login`,
      data: {
        email: authDto.email,
        password: authDto.password,
      },
    });
    //console.log("AuthService :: LOGIN :: ", response);
    return Promise.resolve({
      status: true,
      token: response.data.token,
      user: response.data.results,
      message: "Login Successfull.",
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      token: null,
      user: null,
      message: error ? error.data : "Email or Password worng.",
    });
  }
};

const getLoggedInUser = async (user_id) => {
  try {
    //console.log("getLoggedInUser :: ", { user_id });
    let response = await axios({
      method: "GET",
      url: `/api/auth/loggeduser`,
      params: {
        user_id,
      },
    });
    //console.log("AuthService :: getLoggedInUser :: ", response);
    return Promise.resolve({
      status: true,
      user: response.data.data,
      message: "Token validated successfully.",
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      user: null,
      message: error.data,
    });
  }
};

const resetPassword = async (email) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/auth/resetPassword`,
      data: {
        email,
      },
    });
    console.log("UserService :: resetPassword :: ", response);
    return Promise.resolve({
      isSuccess: true,
      user: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      isSuccess: false,
      users: null,
    });
  }
};

const validateOTP = async (data) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/auth/validateOTP`,
      data: {
        ...data,
      },
    });
    console.log("UserService :: validateOTP :: ", response);
    return Promise.resolve({
      status: true,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
    });
  }
};

const changePassword = async (user_id, formValues) => {
  try {
    let response = await axios({
      method: "POST",
      url: `api/users/changePassword`,
      data: {
        user_id,
        formValues,
      },
    });
    console.log("UserService :: changePassword :: ", response);
    return Promise.resolve({
      status: true,
      user: response.data.data,
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      users: null,
    });
  }
};

export { login, getLoggedInUser, resetPassword, validateOTP, changePassword };
