import { Helmet } from "react-helmet-async";
// @mui
import { Button, Container, Grid, Typography } from "@mui/material";
// components
import { useSettingsContext } from "../components/settings";
// routes
import { PATH_DASHBOARD } from "../routes/paths";
// components
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { useAuthContext } from "src/auth/useAuthContext";

import { useState } from "react";
import TenantDetails from "src/sections/tenants/TenantDetails";

// ----------------------------------------------------------------------

export default function PageTenantCreate() {
  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();

  const initialTenantState = {
    first_name: "",
    last_name: "",
    tenant_name: "",
    contact_email: "",
    contact_phone: "",
  };

  const [formValues, setFormValues] = useState(initialTenantState);

  return (
    <>
      <Helmet>
        <title> Tenant | Glacier </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        {/* <Typography variant="h3" component="h1" paragraph>
          Stories
        </Typography> */}
        <CustomBreadcrumbs
          heading="Tenant Create"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "Tenant", href: PATH_DASHBOARD.tenant.root },
            { name: "Create", href: PATH_DASHBOARD.tenant.new },
          ]}
          //   action={
          //     <Button
          //       // component={RouterLink}
          //       to={PATH_DASHBOARD.user.new}
          //       variant="contained"
          //       startIcon={<Iconify icon="eva:plus-fill" />}
          //     >
          //       New User
          //     </Button>
          //   }
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TenantDetails
              formValues={formValues}
              setFormValues={setFormValues}
              initialTenantState={initialTenantState}
              isEditPage={false}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
