import axios from "../utils/axios";

const postStory = async (
  user_id,
  account_id,
  story_name,
  formValues,
  storyLinks,
  referenceUsed
) => {
  console.log("STORY_SERVICE :: ", formValues);
  try {
    let response = await axios({
      method: "POST",
      url: `api/stories/addStory`,
      data: {
        user_id,
        account_id,
        story_name,
        formValues,
        storyLinks,
        referenceUsed,
      },
      // headers: { Authorization: `Bearer ${token}` },
    });

    // if (response.status === 200) {
    //   callback(null, response.data);
    // }
    console.log("AccountService :: postStory :: ", response);
    return Promise.resolve({
      status: true,
      data: response.data.data,
      /* token: response.data.token,
        user: response.data.results, */
    });
  } catch (error) {
    console.error(error);
    return Promise.resolve({
      status: false,
      data: null,
    });
  }
};

export { postStory };
