// ----------------------------------------------------------------------

import _ from "lodash";

function path(root, sublink) {
  // console.log(`${root}${sublink}`);
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  resetpassword: "/resetPassword",
};

export const PATH_NOT_FOUND = "/404";

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  stories: {
    root: path(ROOTS_DASHBOARD, "/stories"),
    viewStory: path(ROOTS_DASHBOARD, "/stories/viewstory"),
    view: (id) => path(ROOTS_DASHBOARD, `/stories/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/stories/${id}/edit`),
    new: path(ROOTS_DASHBOARD, "/stories/new"),
  },
  reference: path(ROOTS_DASHBOARD, "/reference"),
  //welcome: path(ROOTS_DASHBOARD, "/welcome"),
  settings: path(ROOTS_DASHBOARD, "/settings"),
  handbook: {
    root: path(ROOTS_DASHBOARD, "/handbook"),
    view: path(ROOTS_DASHBOARD, "/handbook/view"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/users"),
    new: path(ROOTS_DASHBOARD, "/users/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/users/${id}/edit`),
  },
  account: {
    root: path(ROOTS_DASHBOARD, "/accounts"),
    new: path(ROOTS_DASHBOARD, "/accounts/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/accounts/${id}/edit`),
  },
  attributes: {
    root: (type) => {
      type = _.lowerCase(type);
      return path(ROOTS_DASHBOARD, `/attributes/${type}`);
    },
    new: (type) => {
      type = _.lowerCase(type);
      return path(ROOTS_DASHBOARD, `/attributes/${type}/new`);
    },
    edit: (type) => {
      type = _.lowerCase(type);
      return path(ROOTS_DASHBOARD, `/attributes/${type}/edit`);
    },
    options: {
      root: (type) => {
        type = _.lowerCase(type);
        return path(ROOTS_DASHBOARD, `/attributes/${type}/options`);
      },
      new: (type) => {
        type = _.lowerCase(type);
        return path(ROOTS_DASHBOARD, `/attributes/${type}/options/new`);
      },
      edit: (type) => {
        type = _.lowerCase(type);
        return path(ROOTS_DASHBOARD, `/attributes/${type}/options/edit`);
      },
    },
  },
  tenant: {
    root: path(ROOTS_DASHBOARD, "/tenants"),
    new: path(ROOTS_DASHBOARD, "/tenants/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/tenants/${id}/edit`),
  },
  // reports: path(ROOTS_DASHBOARD, "/reports"),
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    configure: path(ROOTS_DASHBOARD, "/reports/configure"),
  },
};
